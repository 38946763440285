import { useCallback } from 'react';
import { Formik, useFormikContext } from 'formik';

import { Box, Stack, Text } from '@vinomofo/components';
import CodeInput from '../../../components/verification-code-input';
import useApplicationState from '../../../hooks/use-application-state';
import useAuth from '../../../hooks/use-auth';
import { validate, validatePresence, validateFormat } from '../../../utils/form-validation';
import LoginWithCredentialsLink from '../../shared/_login-with-credentials';
import ResendCode from '../../shared/_resend-code';
import { useParams } from 'react-router-dom';

/* eslint-disable */

const Render = () => {
  const { email: encodedEmail } = useParams<{ email: string }>();
  const email = decodeURIComponent(encodedEmail);

  const { setError } = useApplicationState();
  const { verifyPasswordless } = useAuth();

  const onSubmit = ({ code }: { code: string }) => {
    verifyPasswordless({ email, code }).catch((err) => {
      setError(null as any);
      setError(err.description);
    });
  };

  const FormikCodeInput = () => {
    const { values, setFieldValue, submitForm } = useFormikContext<{ code: string }>();

    const update = useCallback(
      (code: string) => {
        setFieldValue('code', code);
      },
      [setFieldValue],
    );

    return <CodeInput defaultValue={values.code} onChange={update} onComplete={submitForm} />;
  };

  return (
    <Formik
      validateOnMount={true}
      initialValues={{ code: '' }}
      validate={validate([validatePresence('code'), validateFormat('code', { format: /[0-9]{6}/ })])}
      onSubmit={onSubmit}
    >
      {({ isValid, isSubmitting, submitForm }) => {
        const message = "Can't access your email? Too many unreads?";
        return (
          <Stack space={1}>
            <Text
              fontFamily="heading"
              fontSize={['20px', '20px', '24px']}
              fontWeight={700}
              color="white"
              textAlign="center"
            >
              Check your email for the sign-in link
            </Text>
            <Box display="flex" justifyContent="center">
              <Box width="100%" maxWidth="360px">
                <Stack space={4}>
                  <Text textAlign="center" variant="sm" color="white">
                    We&apos;ve just sent a sign-in link to <strong>{email}</strong>. This link expires shortly, so
                    please access it soon.
                  </Text>
                  <Text textAlign="center" variant="sm" color="white">
                    If you don&apos;t see the mail in your inbox, check your spam folder. Once signed in, you can close
                    this window anytime.
                  </Text>
                  {/*<Stack space={4}>*/}
                  {/*  <FormikCodeInput />*/}
                  {/*  <Button type="submit" variant="primary" disabled={!isValid || isSubmitting} onClick={submitForm}>*/}
                  {/*    {isSubmitting ? 'Verifying...' : 'Continue'}*/}
                  {/*  </Button>*/}
                  {/*</Stack>*/}
                  <Stack space={2}>
                    <ResendCode email={email} />
                    <LoginWithCredentialsLink {...{ message }} />
                  </Stack>
                </Stack>
              </Box>
            </Box>
          </Stack>
        );
      }}
    </Formik>
  );
};

export default Render;
