import { Formik } from 'formik';
import JWT from 'jsonwebtoken';
import { useParams } from 'react-router-dom';

import { Box, Button, Logo, Stack, Text } from '@vinomofo/components';

import { UserPayload } from '../../../api/src/types';
import CheckboxField from '../../components/checkbox-field';
import TextField from '../../components/text-field';
import {
  dateStringFromFormattedDateString,
  validate,
  validateBirthdate,
  validateNameBasedOnFlag,
  validatePresence,
} from '../../utils/form-validation';
import { updateUser } from '../../utils/identity-api-client';
import { logDebug, logError } from '../../utils/logger';
import { dateMask } from '../../utils/masks';
import TermsAcceptance from '../shared/_terms-acceptance';
import { HideShowPasswordIcon } from '@vinomofo/icons';
import { useEffect, useState } from 'react';
import { trackEvent } from '../../utils/track-event';
import { getParameterFromURLSearch } from '../../utils/get-parameter-from-url';
import styled from 'styled-components';

const StyledInput = styled(TextField)`
  border: 0;
  border-bottom: 2px solid ${({ theme }) => theme.colors.divider};
  text-align: center;
  background: none;
  border-radius: 0;
  color: white;

  ::placeholder {
    color: white;
  }

  &:focus {
    box-shadow: none;
    border-color: ${({ theme }) => theme.colors.brand};

    ::placeholder {
      opacity: 0;
    }
  }
`;

const StyledStack = styled(Stack)`
  label {
    color: white;
  }

  label + p {
    color: white;
  }

  span {
    color: white;
  }

  > div > div > div > div > p {
    padding: 3px 5px;
    background-color: #cf2c30;
    color: white;
    border-radius: 4px;
  }
`;

const Render = () => {
  const { token } = useParams<{ token: string }>();
  const [revealPassword, setRevealPassword] = useState<boolean>(false);

  const handleContinuation = () => {
    const state = getParameterFromURLSearch('state', window.location.search);

    const { continuation } = JWT.decode(token, { complete: false, json: true })!;
    const continuationUrl = `${continuation}?state=${state}`;

    window.location.replace(continuationUrl);
  };

  const {
    firstName: auth0FirstName,
    lastName: auth0LastName,
    birthdate: auth0Birthdate,
    social: auth0SocialFlag,
    email: auth0Email,
    continuation: auth0Continuation,
  } = JWT.decode(token, {
    complete: false,
    json: true,
  })!;

  useEffect(() => {
    trackEvent('User Profile Started', {
      category: 'Auth',
      email: auth0Email,
      type: 'organic',
      social: auth0SocialFlag,
      url: auth0Continuation,
    });
  }, []);

  const handleToggleClick = () => {
    setRevealPassword(!revealPassword);
  };

  return (
    <Formik
      validateOnMount={true}
      initialValues={{
        firstName: (auth0FirstName as string) || '',
        lastName: (auth0LastName as string) || '',
        birthdate: auth0Birthdate as string,
        password: '',
        marketingConsent: true,
        termsAcceptance: true,
        social: (auth0SocialFlag as boolean) || false,
      }}
      validate={validate([
        validatePresence('firstName', {}),
        validateBirthdate('birthdate'),
        validateNameBasedOnFlag('password', !auth0SocialFlag, {}),
      ])}
      onSubmit={(values, actions) => {
        const { firstName, lastName, birthdate, password, marketingConsent, termsAcceptance, social } = values;
        const payload: UserPayload = {
          firstName,
          lastName,
          marketingConsent,
          termsAcceptance,
          password,
          social,
        };

        if (birthdate) {
          payload.birthdate = dateStringFromFormattedDateString(birthdate);
        }

        updateUser(payload, token)
          .then((response) => {
            logDebug('SUCCESS!', response.data);
            trackEvent('User Profile Completed', {
              category: 'Auth',
              email: auth0Email,
              type: 'organic',
              opted_in_to_marketing: marketingConsent,
              terms_acceptance: termsAcceptance,
            });
            handleContinuation();
          })
          .catch((err) => {
            logError('ERROR!', { error: err });
            trackEvent('User Sign-up Failed', {
              category: 'Auth',
              email: auth0Email,
              type: 'organic',
              opted_in_to_marketing: marketingConsent,
              terms_acceptance: termsAcceptance,
            });
            handleContinuation();
          })
          .finally(() => actions.setSubmitting(false));
      }}
    >
      {({ isValid, handleSubmit, handleChange, handleBlur, values, errors, touched, isSubmitting }) => {
        return (
          <Stack space={4}>
            <Box width={150} alignSelf={'center'} mb={4}>
              <Logo vinoColor="white" />
            </Box>
            <Text
              fontFamily="heading"
              fontSize={['20px', '20px', '24px']}
              fontWeight={700}
              color="white"
              textAlign="center"
            >
              Just a little more about you...
            </Text>

            <Box as="form" onSubmit={handleSubmit} display="flex" justifyContent="center">
              <Box width="100%" maxWidth="360px">
                <Stack space={2}>
                  <StyledStack space={1}>
                    <StyledInput
                      label="What should we call you?"
                      name="firstName"
                      hint="We can't just keep calling you Mofo now, can we Mofo?"
                      placeholder="First name"
                      value={values.firstName}
                      errorMessage={errors.firstName}
                      touched={touched.firstName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <StyledInput
                      label="Last name"
                      name="lastName"
                      value={values.lastName}
                      placeholder="Last name"
                      errorMessage={errors.lastName}
                      touched={touched.lastName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isRequired={false}
                    />
                    <StyledInput
                      label="Birthdate"
                      name="birthdate"
                      type="text"
                      hint="Give us a chance to spoil you on your big day."
                      mask={dateMask}
                      placeholder="DD/MM/YYYY"
                      value={values.birthdate}
                      errorMessage={errors.birthdate}
                      touched={touched.birthdate}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {!auth0SocialFlag && (
                      <StyledInput
                        label="Password"
                        name="password"
                        type={revealPassword ? 'text' : 'password'}
                        hint="At least 8 characters long; generously sprinkled with lower case, UPPER CASE and numbers"
                        value={values.password}
                        errorMessage={errors.password}
                        touched={touched.password}
                        placeholder="Sssshhh...."
                        onChange={handleChange}
                        onBlur={handleBlur}
                        rightAdornment={
                          <Button
                            type="button"
                            onClick={handleToggleClick}
                            hoverColor="dorianGray"
                            color={revealPassword ? 'primary' : 'fieldBorder'}
                          >
                            <HideShowPasswordIcon isClicked={!revealPassword} width="24" height="24" />
                          </Button>
                        }
                      />
                    )}
                    <CheckboxField
                      label="It's okay to send me emails with amazing deals!"
                      name="marketingConsent"
                      errorMessage={errors.marketingConsent}
                      touched={touched.marketingConsent}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isChecked
                    />
                  </StyledStack>

                  <Button type="submit" variant="primary" disabled={!isValid || isSubmitting}>
                    Continue
                  </Button>
                </Stack>
                <Box height="20px" />
                <TermsAcceptance color="white" />
              </Box>
            </Box>
          </Stack>
        );
      }}
    </Formik>
  );
};

export default Render;
