import { useState, useEffect } from 'react';
import axios, { AxiosError } from 'axios';

interface StorefrontBackground {
  fileName: string;
  size: number;
  url: string;
}

interface Storefront {
  name: string;
  market: string;
  defaultLoginBackground: StorefrontBackground | null;
  eventLoginBackground: StorefrontBackground | null;
  eventEndTime: string | null;
}

const useStorefrontBackground = (redirect_uri: string | null | undefined) => {
  const [backgroundUrl, setBackgroundUrl] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const defaultBackgroundUrl =
    'https://res.cloudinary.com/vinomofo/image/upload/q_auto/v1713746713/assets/vinomofo-identity-bg.png';

  useEffect(() => {
    const fetchStorefront = async () => {
      try {
        setIsLoading(true);
        const { data: storefronts } = await axios.get<Storefront[]>(
          `${process.env.REACT_APP_IDENTITY_API_URL}/storefronts.json`.replace('/api/', '/'),
        );

        const isSG = redirect_uri?.includes('vinomofo.com.sg');
        const storefront = storefronts.find((s: Storefront) => s.market === (isSG ? 'SG' : 'AU'));

        if (storefront) {
          const now = new Date();
          const eventEnd = storefront.eventEndTime ? new Date(storefront.eventEndTime) : null;
          const useEventBackground = eventEnd && now < eventEnd && storefront.eventLoginBackground !== null;

          const background = useEventBackground ? storefront.eventLoginBackground : storefront.defaultLoginBackground;

          setBackgroundUrl(background?.url || defaultBackgroundUrl);
        }
        throw new Error('No storefront found');
      } catch (err) {
        const error = err as AxiosError;
        setError(new Error(error.response?.data?.message || 'Failed to fetch storefront data'));
      } finally {
        setIsLoading(false);
      }
    };

    fetchStorefront();
  }, [redirect_uri]);

  return { backgroundUrl, isLoading, error };
};
export default useStorefrontBackground;
