import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import MovingComponent from 'react-moving-text';

import { Box, Button, Stack, Text } from '@vinomofo/components';
import { HideShowPasswordIcon } from '@vinomofo/icons';

import TextField from '../../../components/text-field';
import useApplicationState from '../../../hooks/use-application-state';
import useAuth from '../../../hooks/use-auth';
import { validate, validateEmail, validatePresence } from '../../../utils/form-validation';
import LoginWithCode from '../../shared/_login-with-code';
import SocialProviders from '../../shared/_social-providers';
import styled from 'styled-components';
import { Link as RouteLink } from 'react-router-dom';
import JoinInstead from '../../shared/_join-instead';
import TermsAcceptance from '../../shared/_terms-acceptance';
import { trackEvent, trackUserLoginOption } from '../../../utils/track-event';
import { ErrorBox, HyperLink } from '../../../components/layout';
import { getHashParams } from '../../../utils/hash-params';
import emailSpellChecker from '@zootools/email-spell-checker';
import { MailSuggestion } from '@zootools/email-spell-checker/dist/lib/types';
import EmailSuggestion from '../../../components/email-suggestion';
import { getParameterFromURLSearch } from '../../../utils/get-parameter-from-url';

const Link = styled(RouteLink)`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 500;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const StyledFieldWrapper = styled(Box)`
  label {
    color: white;
  }

  > div > div > div > p {
    padding: 3px 5px;
    background-color: #cf2c30;
    color: white;
    border-radius: 4px;
  }
`;

const StyledInput = styled(TextField)`
  border: 0;
  border-bottom: 2px solid ${({ theme }) => theme.colors.divider};
  text-align: center;
  background: none;
  border-radius: 0;
  color: white;

  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus,
  :-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    background-color: transparent !important;
    -webkit-text-fill-color: white !important;
  }

  :-webkit-autofill {
    background-color: transparent !important;
    color: white !important;
  }

  :-moz-autofill {
    background-color: transparent !important;
    color: white !important;
  }

  ::placeholder {
    color: white;
  }

  &:focus {
    box-shadow: none;
    border-color: ${({ theme }) => theme.colors.brand};

    ::placeholder {
      opacity: 0;
    }
  }
`;

const Render = (): JSX.Element => {
  const [revealPassword, setRevealPassword] = useState<boolean>(false);
  const [suggestion, setSuggestion] = useState<MailSuggestion | null | undefined>(null);
  const [emailValue, setEmailValue] = useState<any>(null);
  const [debouncedInputValue, setDebouncedInputValue] = useState<string | null | undefined>(null);
  const [rerenderKey, setRerenderKey] = useState(0);
  const { loginWithCredentials } = useAuth();
  const { formData, setError, setFormData } = useApplicationState();

  const handleToggleClick = () => {
    setRevealPassword(!revealPassword);
  };
  const AnimationsForChaining = ['bounce'];
  const [animationIndex, setAnimationIndex] = useState(0);
  const [animationType, setAnimationType] = useState(AnimationsForChaining[0]);
  const { error } = useApplicationState();

  const redirect_uri = getParameterFromURLSearch('redirect_uri', window.location.search);

  const origin = redirect_uri?.split('?')[0];
  const hashParams = getHashParams(window.location.hash);
  let returnToParam = hashParams.returnTo;
  const emailParam = hashParams.email || '';

  const returnOrigin =
    returnToParam && returnToParam?.includes('vinomofo.com') ? '' : origin?.slice(0, origin?.lastIndexOf('/'));
  if (returnToParam?.includes('/wine-clubs/') && returnToParam?.includes('/mofo-club'))
    returnToParam = '/wine-clubs/mofo-club';
  if (returnToParam?.includes('/wine-clubs/') && returnToParam?.includes('/black-market-club'))
    returnToParam = '/wine-clubs/black-market-club';
  const returnTo = returnToParam ? `${returnOrigin}${returnToParam}` : 'https://vinomofo.com';
  const showContinueAsGuest =
    returnToParam && (returnToParam.indexOf('/cart') > -1 || returnToParam.indexOf('/guest-checkout') > -1);

  const handleChainAnimation = () => {
    setAnimationIndex(animationIndex + 1);
    setAnimationType(AnimationsForChaining[animationIndex + 1]);
  };

  useEffect(() => {
    const delayInputTimeoutId = setTimeout(() => {
      setDebouncedInputValue(suggestion?.full);
    }, 1000);
    return () => clearTimeout(delayInputTimeoutId);
  }, [suggestion, 1000]);

  return (
    <>
      <Formik
        validateOnMount={true}
        enableReinitialize
        initialValues={{ email: emailParam, password: '' }}
        validate={(values) => {
          setSuggestion(null);
          const suggestion = emailSpellChecker.run({
            email: values.email,
          });
          if (suggestion) {
            setSuggestion(suggestion);
          }
          const validator = validate([validateEmail('email'), validatePresence('password', {})]);
          const result = validator(values);
          return result;
        }}
        onSubmit={(values, actions) => {
          const { email, password } = values;
          setError('');
          setFormData(values);
          trackUserLoginOption('email');
          loginWithCredentials({ email, password })
            .catch((err) => {
              setError(err.description);
              actions.setFieldValue('password', '');
              setRerenderKey((prevKey) => prevKey + 1);
              trackEvent('User Login Failed', {
                category: 'Auth',
                provider: 'email',
                action: 'Login failed',
              });
            })
            .finally(() => {
              actions.setSubmitting(false);
            });
        }}
        validateOnChange
      >
        {({
          isValid,
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          errors,
          touched,
          isSubmitting,
          setFieldValue,
        }) => {
          return (
            <Stack space={4}>
              <SocialProviders />

              <Box height="1px" backgroundColor="#77B204" mx={[-4, -5]} />

              <Stack space={4}>
                <Text
                  fontFamily="heading"
                  fontSize={['20px', '20px', '24px']}
                  fontWeight={700}
                  color="white"
                  textAlign="center"
                >
                  or login with your details below
                </Text>

                <Box as="form" onSubmit={handleSubmit} display="flex" justifyContent="center" key={rerenderKey}>
                  <Box width="100%" maxWidth="360px">
                    <Stack space={2}>
                      <StyledFieldWrapper>
                        <StyledInput
                          label="Email"
                          name="email"
                          type="email"
                          placeholder="Your email address"
                          value={values.email}
                          errorMessage={
                            Array.isArray(errors.email) ? errors.email.join(', ') : errors?.email?.toString()
                          }
                          touched={!!touched.email}
                          onChange={handleChange}
                          onBlur={(e) => {
                            setEmailValue(values.email);
                            handleBlur(e);
                          }}
                          key={emailValue}
                        />

                        {debouncedInputValue && (
                          <EmailSuggestion
                            style={{
                              marginTop: -20,
                              marginBottom: 20,
                              zIndex: 2,
                              position: 'relative',
                            }}
                            onAccept={() => {
                              setEmailValue(suggestion?.full);
                              setFieldValue('email', suggestion?.full);
                            }}
                            onIgnore={() => {
                              const to = setTimeout(() => {
                                setDebouncedInputValue(null);
                                clearTimeout(to);
                              }, 1000);
                            }}
                            text={
                              <>
                                Did you mean <strong color="black">{debouncedInputValue}</strong> ?
                              </>
                            }
                          />
                        )}
                        <StyledInput
                          label="Password"
                          name="password"
                          placeholder="Your password"
                          type={revealPassword ? 'text' : 'password'}
                          value={values.password}
                          errorMessage={errors.password}
                          touched={touched.password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          rightAdornment={
                            <Button
                              type="button"
                              onClick={handleToggleClick}
                              hoverColor="dorianGray"
                              color={revealPassword ? 'primary' : 'fieldBorder'}
                            >
                              <HideShowPasswordIcon isClicked={!revealPassword} width="24" height="24" />
                            </Button>
                          }
                        />
                        <Button
                          type="submit"
                          variant="primary"
                          width="100%"
                          disabled={!isValid || isSubmitting}
                          mt="6px"
                        >
                          Log in
                        </Button>
                      </StyledFieldWrapper>
                      <Box width="100%" maxWidth="360px">
                        <JoinInstead />
                        <Box marginTop="-18px">
                          <Text fontSize="12px" textAlign={'right'}>
                            <Link to="/auth/login/forgot-password">Forgot Password?</Link>
                          </Text>
                        </Box>
                      </Box>
                      {error && (
                        <ErrorBox>
                          {showContinueAsGuest ? (
                            <>
                              {error} <br />
                              Please try again or choose from the options below.
                              <HyperLink
                                href={
                                  showContinueAsGuest && formData?.email
                                    ? `${returnTo}?email=${formData.email}`
                                    : `${returnTo}`
                                }
                              >
                                {' '}
                                Otherwise, continue to checkout as guest.
                              </HyperLink>
                            </>
                          ) : (
                            <>
                              {error} <br /> Please try again or choose from the options below.
                            </>
                          )}
                        </ErrorBox>
                      )}
                      {error ? (
                        <MovingComponent
                          onAnimationEnd={handleChainAnimation}
                          type={animationType}
                          duration="1000ms"
                          timing="linear"
                          fillMode="forwards"
                          iteration={1}
                        >
                          <LoginWithCode />
                        </MovingComponent>
                      ) : (
                        <LoginWithCode />
                      )}
                      <TermsAcceptance color="white" />
                    </Stack>
                  </Box>
                </Box>
              </Stack>
            </Stack>
          );
        }}
      </Formik>
    </>
  );
};

export default Render;
