import { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { Box, Button, Input, Stack, Text } from '@vinomofo/components';

import useApplicationState from '../../../hooks/use-application-state';
import useAuth from '../../../hooks/use-auth';
import { validate, validateEmail } from '../../../utils/form-validation';
import JoinInstead from '../../shared/_join-instead';
import LoginWithCredentialsLink from '../../shared/_login-with-credentials';
import SocialProviders from '../../shared/_social-providers';
import TermsAcceptance from '../../shared/_terms-acceptance';
import { errorDataBuilder, ErrorNotifier } from '../../../utils/error-notifier';
import { getHashParams } from '../../../utils/hash-params';
import { trackUserLoginOption } from '../../../utils/track-event';
import emailSpellChecker from '@zootools/email-spell-checker';
import { MailSuggestion } from '@zootools/email-spell-checker/dist/lib/types';
import EmailSuggestion from '../../../components/email-suggestion';

const StyledInput = styled(Input)`
  border: 0;
  border-bottom: 2px solid ${({ theme }) => theme.colors.divider};
  text-align: center;
  background: none;
  border-radius: 0;
  color: white;

  ::placeholder {
    color: white;
  }

  &:focus {
    box-shadow: none;
    border-color: ${({ theme }) => theme.colors.brand};

    ::placeholder {
      opacity: 0;
    }
  }
`;

const Render = (props: { email: string }) => {
  const { email: emailData } = props;
  const hashParams = getHashParams(window.location.hash);
  const emailParam = hashParams.email;

  const { setError, setFormData } = useApplicationState();
  const { startPasswordlessLogin } = useAuth();
  const history = useHistory();

  const [suggestion, setSuggestion] = useState<MailSuggestion | null | undefined>(null);
  const [emailValue, setEmailValue] = useState<any>(null);
  const [debouncedInputValue, setDebouncedInputValue] = useState<string | null | undefined>(null);
  const encodedEmailParam = emailData ? decodeURIComponent(emailData) : '';
  const hasEmailParam = undefined !== encodedEmailParam;

  useEffect(() => {
    window.analytics?.page('login');
  }, []);

  const onSubmit = async ({ email }: { email: string }) => {
    try {
      setError('');
      setFormData({ email });
      await startPasswordlessLogin({ email });

      // passwordless / magiclink track
      trackUserLoginOption('passwordless');

      const hashParams = getHashParams(window.location.hash);
      const returnTo = hashParams.returnTo || 'https://vinomofo.com';

      const encodedEmail = encodeURIComponent(email);
      history.push(`/auth/login/verify/${encodedEmail}?returnTo=${returnTo}`);
    } catch (err: any) {
      ErrorNotifier.notify(err, errorDataBuilder('API', 400, err));
      setError(err.message);
    }
  };

  useEffect(() => {
    const delayInputTimeoutId = setTimeout(() => {
      setDebouncedInputValue(suggestion?.full);
    }, 1000);
    return () => clearTimeout(delayInputTimeoutId);
  }, [suggestion, 1000]);

  return (
    <Formik
      validateOnMount={true}
      initialValues={{ email: hasEmailParam ? decodeURIComponent(encodedEmailParam!) : '' }}
      validate={(values) => {
        setSuggestion(null);
        const suggestion = emailSpellChecker.run({
          email: values.email,
        });
        if (suggestion) {
          setSuggestion(suggestion);
        }
        validate([validateEmail('email')]);
      }}
      onSubmit={onSubmit}
    >
      {({ isValid, handleSubmit, handleChange, handleBlur, values, isSubmitting, setFieldValue }) => {
        const message = "Can't access your email? Too many unreads?";
        return (
          <Stack space={4}>
            <SocialProviders />

            <Box height="1px" backgroundColor="#77B204" mx={[-4, -5]} />

            <Stack space={1}>
              <Text
                fontFamily="heading"
                fontSize={['20px', '20px', '24px']}
                fontWeight={700}
                color="white"
                textAlign="center"
              >
                Enter your email address
              </Text>

              <Box as="form" onSubmit={handleSubmit} display="flex" justifyContent="center">
                <Box width="100%" maxWidth="360px">
                  <Stack space={4}>
                    <Stack space={4}>
                      <StyledInput
                        type="email"
                        name="email"
                        placeholder="Your email address"
                        autoComplete="email"
                        defaultValue={values.email || emailParam}
                        onChange={handleChange}
                        onBlur={(e) => {
                          setEmailValue(values.email);
                          handleBlur(e);
                        }}
                        key={emailValue}
                      />
                      {debouncedInputValue && (
                        <EmailSuggestion
                          style={{
                            marginTop: -20,
                            zIndex: 2,
                            position: 'relative',
                          }}
                          onAccept={() => {
                            setEmailValue(suggestion?.full);
                            setFieldValue('email', suggestion?.full);
                          }}
                          onIgnore={() => {
                            const to = setTimeout(() => {
                              setDebouncedInputValue(null);
                              clearTimeout(to);
                            }, 1000);
                          }}
                          text={
                            <>
                              Did you mean <strong color="black">{debouncedInputValue}</strong> ?
                            </>
                          }
                        />
                      )}
                      <Button type="submit" variant="primary" disabled={!isValid || isSubmitting}>
                        {hasEmailParam ? 'Resend Link' : 'Continue'}
                      </Button>
                    </Stack>
                    <Stack space={2}>
                      <JoinInstead />
                      {/*<PasswordlessExplanation />*/}
                      <LoginWithCredentialsLink {...{ message }} />
                      <TermsAcceptance color="white" />
                    </Stack>
                  </Stack>
                </Box>
              </Box>
            </Stack>
          </Stack>
        );
      }}
    </Formik>
  );
};

export default Render;
